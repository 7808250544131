<template>
  <div>
    <CAlert show color="warning" class="mb-0">
      <h5>Unsigned Contract!</h5>
      <div class="d-flex flex-gap-2 mt-3">
        <CIcon :height="24" name="cil-warning" />
        <span>
          <CLink :to="{ name: 'Edit Restaurant', params: { id: id }, query: { tab: 8 } }">Sign the contract</CLink> or
          <a role="button" @click="showConfirm = true">send mail to sign</a>.
        </span>
      </div>
      <CElementCover :opacity="0.4" v-show="processing" />
    </CAlert>

    <CModal class="confirm-modal"
      title="Confirm"
      color="dark"
      :show.sync="showConfirm"
      :closeOnBackdrop="false"
      :centered="false"
    >
      <div class="form-group" v-if="emails.length > 1">
        <label class="d-block">Select an email to send:</label>
        <div class="mail-group">
          <div v-for="(mail, index) in emails" class="d-flex flex-row flex-gap-2 align-items-center">
            <input type="radio" :id="`mail_${index}`" name="email" :value="mail" v-model="selectedMail">
            <label :for="`mail_${index}`">{{ mail }}</label>
          </div>
        </div>
      </div>

      <div class="alert alert-info mb-0">
        <p>⚠️ Mail will be sent to <strong>{{ selectedMail }}</strong>.</p>
        Are you sure you want to continue?
      </div>

      <template #footer class="p-1">
        <CButton @click="showConfirm = false" color="link">Cancel</CButton>
        <CButton @click="sendMail" size="sm" color="info">
          <CIcon name="cil-send" /> Send Mail
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
export default {
  props: {
    id: { default: null, type: Number },
    email: { default: null, type: String },
  },
  data() {
    return {
      selectedMail: null,
      processing: false,
      showConfirm: false,
    }
  },
  computed: {
    emails() {
      if (!this.email) {
        return [];
      }

      const emails = this.email.split(";");
      this.selectedMail = emails[0];
      return emails;
    }
  },
  methods: {
    async sendMail() {
      this.showConfirm = false;
      this.processing = true;
      try {
        await this.$axios.post(this.$backend.RESTAURANT.SEND_MAIL_TO_SIGN_CONTRACT.replace("{id}", this.id), {
          email: this.selectedMail
        });
        this.$toast.success(`Email successfully sent to ${this.selectedMail}`);
      } catch (error) {
        this.$toast.error(`${error.response.data.message}`);
      } finally {
        this.processing = false;
      }
    }
  },
}
</script>

<style>
.confirm-modal .modal-header {
  padding: 0.75rem !important;
}

.confirm-modal .modal-footer {
  padding: 0.25rem !important;
}

.confirm-modal {
  font-size: 1rem;
}
</style>

<style scoped>
span {
  font-size: 1rem;
}

span a {
  font-weight: bold;
  color: #815c15;
  text-decoration: underline;
}
.mail-group {
  padding-left: 1rem;
}
.mail-group label {
  margin-bottom: 0;
}
</style>
